<template>
  <v-layout class="d-flex pa-3 pa-sm-0 flex-wrap mb-4">
    <v-flex
      lg4
      xl2
      v-for="i in parseInt(number)"
      :key="i"
      class="
        global-products
        d-flex
        justify-center
        flex-wrap
        pa-xl-2 pa-lg-2 pa-md-0
      "
    >
      <v-skeleton-loader
        class="ma-9"
        width="344px"
        type="card"
      ></v-skeleton-loader>
    </v-flex>
  </v-layout>
</template>
<script>
export default {
  props: ["number"],
};
</script>
<style scoped></style>
